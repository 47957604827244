import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { ApiService } from 'src/app/services/api.service';
import { Util } from '../../lib/util';

@Component({
  selector: 'app-headlines',
  templateUrl: './headlines.component.html',
  styleUrls: ['./headlines.component.scss'],
})
export class HeadlinesComponent implements OnInit {
  defaultGridImages = [
    { photo: "assets/images/7.jpg", srcset: "" },
    { photo: "assets/images/8.jpg", srcset: "" },
    { photo: "assets/images/9.jpg", srcset: "" },
    // { photo: "assets/images/4.jpeg", srcset: "" },
    // { photo: "assets/images/5.jpeg", srcset: "" },
  ]
  gridImages=[]
  skeletonDisplay = false;
  slideOpts;
  constructor(
    private storage:Storage,
    private api:ApiService
  ) { }

  ngOnInit() {
    this.slideOpts = {
      autoplay: {
        delay: 8000,
      },
      effect: 'cube',
      initialSlide: 0,
      speed: 300000,
    };

    // this.gridImages = this.gridImages.map(image => {
    //   let srcset = image.photo.replace(".", "@2x.") + ' 1000w,' + image.photo.replace(".", "@3x.") + ' 1500w'
    //   return { ...image, srcset: srcset }
    // })
    // this.defaultGridImages = this.defaultGridImages.map(image => {
    //   let srcset = image.photo.replace(".", "@2x.") + ' 1000w,' + image.photo.replace(".", "@3x.") + ' 1500w'
    //   return { ...image, srcset: srcset }
    // })
    setTimeout(() => {
      this.getHeaderLine();
    }, 1000);
  }


  async getHeaderLine() {
    try {
      this.slideOpts = {
        autoplay: {
          delay: 8000,
        },
        effect: 'cube',
        initialSlide: 0,
        speed: 4000,
      };

      this.storage.get('headerLineNews').then(headerLineNews => {
        if (headerLineNews) {

          this.storage.get('headerLineLastUpdateTime').then(appLastUpdate => {
            this.api.getAdvertisementsLastUpdateTime().subscribe((newsLastUpdateTime) => {
              if (!appLastUpdate || Util.getDateString(newsLastUpdateTime['lastUpdateTime'], "YYYY-MM-DD hh:mm:ss") > appLastUpdate) {
                this.storage.set('headerLineLastUpdateTime', Util.getDateString(newsLastUpdateTime['lastUpdateTime'], "YYYY-MM-DD hh:mm:ss")).then();
                this.getNewAdvertisements();
              }
              else {
                this.gridImages = headerLineNews
                setTimeout(() => {
                  this.skeletonDisplay = false;
                }, 1000);
              }
            })
          })
        }
        else {

          this.api.getAdvertisementsLastUpdateTime().subscribe(newsLastUpdateTime => {
            this.storage.set('headerLineLastUpdateTime', Util.getDateString(newsLastUpdateTime['lastUpdateTime'], "YYYY-MM-DD hh:mm:ss")).then();
            this.getNewAdvertisements()
          })
        }
      })
    } catch (error) {
      this.gridImages = []
      this.skeletonDisplay = true;
    }
  }

  getNewAdvertisements() {
    this.api.getAdvertisements().subscribe(advertisements => {
      if(advertisements.result&&advertisements.data&&advertisements.data.length>0){
        let datas = advertisements.data
        datas = datas.map(advertisement => {
          return {description:`${advertisement.description}`, photo: `${advertisement.path}/preview`, "href": advertisement['openedUrl'] }
        })
        this.gridImages = datas

        this.storage.set('headerLineNews',this.gridImages).then()
        setTimeout(() => {
          this.skeletonDisplay = false;
        }, 1000);
      }
      else{
        this.skeletonDisplay = false;
      }
    })
  }

  imgLoaded(event) {

    event.target.style.opacity = 1
  }

  openUrlClick(event,href) {
    event.stopPropagation()
    window.open(href, "_blank");
  }
}
