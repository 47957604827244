import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-related-question-carousel',
  templateUrl: './related-question-carousel.component.html',
  styleUrls: ['./related-question-carousel.component.scss'],
})
export class RelatedQuestionCarouselComponent implements OnInit {

  limit: number = 3;
  columns: number = 3;
  isHandSet: boolean;
  displayQuestions = []
  totalRecords;
  loading: boolean;
  numVisible=1;
  relatedQuestions=[]
  constructor(
    private api:ApiService,
    private breakpointObserver:BreakpointObserver,
    private navCtrl:NavController
  ) {
    breakpointObserver.observe([Breakpoints.HandsetPortrait]).subscribe(result => {
      this.isHandSet = result.matches;
    });
    breakpointObserver.observe([Breakpoints.Medium,Breakpoints.Large]).subscribe(result => {
      if(result.matches){
        this.columns=2;
      }
    });
    breakpointObserver.observe([Breakpoints.Large,Breakpoints.XLarge]).subscribe(result => {
      if(result.matches){
        this.columns=3;
      }
    });
    breakpointObserver.observe([Breakpoints.Medium,Breakpoints.Large,Breakpoints.XLarge]).subscribe(result => {
      if(!result.matches){
        this.columns=1;
      }
    });
  }

  ngOnInit() {
    this.api.getQAByPage(1, this.limit,'viewCnt').subscribe(relatedQuestions=>{
      if(relatedQuestions['data-key']&&relatedQuestions['data-key'].length>0){
        this.relatedQuestions = this.questionDataHandler(relatedQuestions['data-key'])
        this.totalRecords = relatedQuestions['x-total-count']
        this.loading = false;
      }
    })
  }

  goToQuestionReplys(event,question){
    this.navCtrl.navigateForward(`/tabs/question-replys/${question.id}`)
  }

  paginate(event) {
    let first = event.first
    let page = first == 0 || isNaN(first) ? 1 : (first / this.limit) + 1
    setTimeout(() => {
      this.api.getQAByPage(page, this.limit,'viewCnt').subscribe(questions => {
        this.displayQuestions = this.questionDataHandler(questions['data-key']) ;
        this.totalRecords = questions['x-total-count']
        this.loading = false;
      })
    }, 1000);
  }

  questionTagsClick(event,tagName){
    event.stopPropagation();
    this.navCtrl.navigateForward('tabs/question-dashboard',{ queryParams: { keyinText:tagName} })
  }

  questionDataHandler(questions){
    return questions.map(question => {
      let title;
      let tags;
      let userPhoto;
                title =question.title&&question.title!==''?question.title:question.question.substring(0,20);
       userPhoto =this.api.getAppUserPhotoByUsrId(question.appUserId).pipe(map(photo=> photo.data.filter(data=>data.kind==1)[0]))
      if(question.tags){
        tags = JSON.parse(JSON.parse(JSON.stringify(question.tags)))
      }
      return {...question,tags:tags,userPhoto:userPhoto,title:title}
    });
  }
}
