import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-doctor-list',
  templateUrl: './doctor-list.component.html',
  styleUrls: ['./doctor-list.component.scss'],
})
export class DoctorListComponent implements OnInit {
  userData$;
  userData;
  userFollowedDoctors=[]
  userFollowedDoctors$;
  isHandSet:boolean=false;
  columns=3
  displayDoctors=[];
  limit=6
  totalRecords;
  constructor(
    private api:ApiService,
    private breakpointObserver:BreakpointObserver,
    private navCtrl:NavController,
    private userService:UserService,
  ) {
    breakpointObserver.observe([Breakpoints.HandsetPortrait]).subscribe(result => {
      this.isHandSet = result.matches;
    });
    breakpointObserver.observe([Breakpoints.Medium,Breakpoints.Large]).subscribe(result => {
      if(result.matches){
        this.columns=2
      }
    });
    breakpointObserver.observe([Breakpoints.Large,Breakpoints.XLarge]).subscribe(result => {
      if(result.matches){
        this.columns=3
      }
    });
    breakpointObserver.observe([Breakpoints.Medium,Breakpoints.Large,Breakpoints.XLarge]).subscribe(result => {
      if(!result.matches){
        this.columns=1
      }
    });
   }

  ngOnInit() {
    this.userData$=this.userService.userData$
    this.userData$
      .subscribe(userData =>{
        if(userData){
          this.userData=userData
          this.userFollowedDoctors$=this.userService.userCollections$
          this.userFollowedDoctors$
            .subscribe(collections =>{
              this.userFollowedDoctors = collections;

            } );
          this.userService.getUserCollection();
          this.api.getFollowedDoctorsById(this.userData.id)
          .subscribe(followedDoctors=>{
            if(followedDoctors&&followedDoctors['data-key']&&followedDoctors['data-key'][0]['followedDoctors']){
              this.userFollowedDoctors= JSON.parse(followedDoctors['data-key'][0]['followedDoctors'])
            }
          })
        }
      });

    this.api.getDoctorByPage(1,this.limit).subscribe(doctors=>{
      this.displayDoctors = this.doctorDataHandler(doctors['data-key']);
      this.totalRecords = doctors['x-total-count']
    })
  }

  doctorDataHandler(doctors){

    return doctors.map(doctor=>{
      let photo = this.getDoctorPhoto(doctor.id)
      let replyCount =this.getDoctorQaReplyCount(doctor.appUserId)
      let columnCount =doctor.column? JSON.parse(JSON.parse(JSON.stringify(doctor.column))).length:0;
      let detail  =doctor.detail? JSON.parse(JSON.parse(JSON.stringify(doctor.detail))):[];
      let specialize = detail.filter(data=>data.type ==4).length>0?(detail.filter(data=>data.type ==4)[0]['desc']).split(',').slice(0,3):[]
      let experience = detail.filter(data=>data.type ==2).length>0?(detail.filter(data=>data.type ==2)[0]['desc']).split(',').slice(0,3):[]
      let infoDesc = detail.filter(data=>data.type ==3).length>0?(detail.filter(data=>data.type ==3)[0]['desc']):''
      let subTitle = detail.filter(data=>data.type ==5).length>0?(detail.filter(data=>data.type ==5)[0]['desc']):''
      return {...doctor,subTitle,doctorPhoto:photo,replyCount:replyCount,columnCount:columnCount,specialize:specialize,experience:experience,infoDesc}
    })
  }

  goToDoctorInfo(event,doctor,tabs=0){
    event.stopPropagation();
    this.navCtrl.navigateRoot(`tabs/doctor-info/${doctor.id}`,{ queryParams: { tabs} })
  }

  getDoctorPhoto(doctorId){
    return  this.api.getDoctorPhotoById(doctorId).pipe(map(data=>{
      if(data&&data['data']&&data['data'][0]){
        return (data['data'].filter(data=>{return data.kind ==1}))[0]['photo']
      }
      else{
        return ""
      }
    }))
  }
 isDoctorFollowed(doctorId){
   return  this.userFollowedDoctors&&this.userFollowedDoctors.length>0?this.userFollowedDoctors.some(data=>data.doctorId ==doctorId):false
 }


 followDoctorClick(doctorId){
  this.userService.followDoctor(doctorId)
 }

 unFollowDoctorClick(doctorId){
  this.userService.unFollowDoctor(doctorId)
 }

  getDoctorQaReplyCount(appUserId) {
    let query = `_page=1`
    query += `&qadAppUserId_like=${appUserId}`
    query += `&status_eq=10`

    return this.api.getQAByPageByQuery(query).pipe(map(ret=>{
      return ret['x-total-count']
    }),catchError(error=>{return of(0)}))
  }

  paginate(event) {
    let first = event.first
    let page = first == 0 || isNaN(first) ? 1 : (first / this.limit) + 1


    setTimeout(() => {

      this.api.getDoctorByPage(page, this.limit).subscribe(doctors => {
        this.displayDoctors = this.doctorDataHandler(doctors['data-key']) ;
        this.totalRecords = doctors['x-total-count']
      })
    }, 1000);
  }

  specializeClick(event,specialize){
    event.stopPropagation();
    this.navCtrl.navigateForward('tabs/doctor-dashboard',{ queryParams: { keyinText:specialize} })
  }
  doctorColumnClick(event,doctorId){
    event.stopPropagation();
    this.navCtrl.navigateForward(`tabs/doctor-info/${doctorId}`)
  }
  askDoctorQuestionClick(event,doctor){
    event.stopPropagation();
    this.navCtrl.navigateForward('tabs/ask-question-flow/question-req',{ queryParams: { type: 'symptom',selectedAssignDoctor:doctor} })
  }
}
