import { Injectable } from '@angular/core';
import { NavController, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { BehaviorSubject, from, of, pipe } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Util } from '../shared/lib/util';
import { ApiService } from './api.service';
import { v4 as uuidv4 } from 'uuid';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  _userData = {};
  _roles = [];
  _isLogin;
  _userCollections: any[] = []
  _unReadNotyCount = 0;
  _socialUserInfo: any = {}
  userData$ = new BehaviorSubject(this._userData);
  socialUserInfo$ = new BehaviorSubject(this._socialUserInfo)
  roles$ = new BehaviorSubject(this._roles);
  userCollections$ = new BehaviorSubject(this._userCollections);
  unReadNotyCount$ = new BehaviorSubject(this._unReadNotyCount);
  constructor(private authService: SocialAuthService,
    private storage: Storage,
    private api: ApiService,
    private navCtrl: NavController,
    private toastCtrl: ToastController,

  ) { }
  signInWithGoogle(): void {
    console.log("🚀 ~ file: user.service.ts ~ line 31 ~ UserService ~ signInWithGoogle ~ signInWithGoogle")
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(data => {
      this.socialLoginHandler(data)
    });
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(data => {
      this.socialLoginHandler(data)
    });;
  }

  socialLoginHandler(userData) {
    this._socialUserInfo = userData;
    this.socialUserInfo$.next(userData);
    this.storage.set('socialUserInfo', userData).then()
    let body = { email: userData.email}
    this.api.checkRegisterInfo(body).subscribe(isRegisted => {
      if (isRegisted.result) {
        this.loginHandler(isRegisted['data'][0], true)
      }
      else {
        let addUserBody = { id: 0, status: 10, email: userData.email, userName: userData.name, nickName: userData.name, mobile: uuidv4.v4()  }
        this.api.saveAppUser(addUserBody)
          .subscribe(result => {
            if (result.result) {
              this.addSocalPhotoToAppUser(result, userData)
              this.toastCtrl.create({
                header: `登入成功,正在轉跳頁面`,
                duration: 2000,
              }).then(async toast => {
                toast.present()
                this.loginHandler(result['data'][0], true)
              })
            }
          })
      }
    })
  }
  async addSocalPhotoToAppUser(newUser, socialUserInfo) {
    let photoBody = {
      "kind": 1,
      "appUserId": newUser['data'][0]['id'],
      "photo": await Util.getBase64ImageFromUrl(socialUserInfo.photoUrl)
    }
    this.api.saveAppUserPhoto(photoBody).subscribe(photo => {
    })
  }
  authState$ = this.authService.authState;
  // refreshGoogleToken(): void {
  //   this.authService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
  // }
  // refreshFacebookToken(): void {
  //   this.authService.refreshAuthToken(FacebookLoginProvider.PROVIDER_ID);
  // }
  signOut(): void {
    this.storage.set('socialUserInfo', null).then()
    this.storage.remove('socialUserInfo').then()
  }

  login(account, pwd) {
    let body = { account: account, pwd: pwd }
    return this.api.appUserLogin(body).pipe(map(result => {
      if (result.result) {
        if (result.data[0].status == 10) {
          this.toastCtrl.create({
            header: `登入成功,正在轉跳頁面`,
            duration: 2000,
          }).then(toast => {
            toast.present()
            this.loginHandler(result.data[0], true)
          })
        }
        else {
          this.toastCtrl.create({
            header: `您的信箱尚未認證，請至您的信箱啟用帳號`,
            duration: 2000,
          }).then(toast => {
            toast.present()
          })
        }
      }
      else {
        this.toastCtrl.create({
          header: `登入失敗,請確認您的帳號密碼`,
          duration: 2000,
        }).then(toast => {
          toast.present()
        })
      }
      return of(result.result)
    }))
  }
  logout() {

    this.logoutHandler()
    this.signOut();
  }
  loginHandler(userData, redirect = false) {
    if (userData) {
      let userinfo;
      this.api.getAppUserPhotoByUsrId(userData['id']).pipe(map(ret => ret['result'] ? ret['data'][0] : null)).subscribe(photo => {

        userinfo = { ...userData, photo: photo ? photo['photo'] : null, photoId: photo ? photo['id'] : 0 }
        this.setStorageUserData(userinfo)
        this.getAppUserUnReadNoty();
        if (redirect) {
          this.navCtrl.navigateRoot('tabs/home')
        }

      })
    }
  }
  logoutHandler() {
    this._userData = null;
    this.userData$.next(null)
    this.navCtrl.navigateRoot('tabs/home')
    this.storage.remove('userData').then()
    this.toastCtrl.create({
      header: `登出成功`,
      duration: 2000,
    }).then(toast => {
      toast.present()
    })
  }

  setStorageUserData(data) {
    this.storage.set("userData", data).then(userData => {
      this._userData = userData;
      this.userData$.next(userData)
    })
  }
  getUserCollection() {
    if (this._userData && this._userData['id']) {
      this.api.getFollowedDoctorsById(this._userData['id']).subscribe(collections => {
        this._userCollections = collections['data']
        this.userCollections$.next(collections['data'])
      })
    }
  }

  followDoctor(doctorId) {
    if (this._userData && this._userData['id']) {
      let body = { appUserId: this._userData['id'], kind: 10, title: doctorId, desc: doctorId, collecTbName: "doctor", collecTbId: doctorId };
      this.api.saveCollection(body).subscribe(data => {
        this.getUserCollection()
      })
    }


  }

  unFollowDoctor(doctorId) {
    let collectionId = this._userCollections.filter(collection => collection.doctorId == doctorId)[0]['collectionId']
    this.api.delCollection(collectionId).subscribe(data => {
      this.getUserCollection()
    })
  }

  getAppUserUnReadNoty() {
    if (this._userData && this._userData['id']) {
      let query = `appUserId_eq=${this._userData['id']}&status_eq=10`
      this.api.getAppUserNotyByPage(query).pipe(catchError(err => of(0))).subscribe(ret => {
        this._unReadNotyCount = ret['x-total-count']
        this.unReadNotyCount$.next(ret['x-total-count'])
      })
    }
  }


}
