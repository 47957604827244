import { Component, OnDestroy } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { BaseHeaderComponent} from '../components/base-header/base-header.component';

@Component({
  selector: 'base-page',
  template:``
})

export class BasePage implements OnDestroy{
  public ngDestroy$ = new Subject();
  public interRouter:string;
  public currentPage:string;
  public title:string="標題";
  constructor(
    public navCtrl:NavController,
    public router:Router,
    public route:ActivatedRoute
  ) {
    this.getInterRouter()

  }


  ngOnDestroy(){
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }


  async getInterRouter(){
    this.route.queryParamMap.subscribe(async (queryParamMap)=>{
      this.interRouter= queryParamMap.get('interRouter');
      console.log(`current page ${this.router.url} from ${ this.interRouter}`)
    })
  }

  goBack(event){
    event.stopPropagation();
    this.navCtrl.back();
  }

  handleError(displayMessage,occurredFunction) {
    let currentRouter=this.router.url;
    let message={occurredPage:this.currentPage?this.currentPage:currentRouter,occurredFunction:occurredFunction,displayMessage:displayMessage,}
    this.navCtrl.navigateRoot(`error`, { queryParams: { interRouter: currentRouter,message: JSON.stringify(message) } });
  }
}
