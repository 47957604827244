import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './pages/error/error.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: '',
    redirectTo: '/tabs/home',
    pathMatch: 'full'
  },
  // {
  //   path: 'chatroom',
  //   loadChildren: () => import('./chatroom/chatroom.module').then(m => m.ChatroomModule)
  // },
  {
    path:'error',
    component:ErrorComponent
  },
  { path: '**', redirectTo: 'error' },




];
@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
