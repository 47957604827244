import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-request-question',
  templateUrl: './request-question.component.html',
  styleUrls: ['./request-question.component.scss'],
})
export class RequestQuestionComponent implements OnInit {

  constructor(
    private navCtrl:NavController
  ) { }
  ngOnInit() {}
  requestQuestionClick(type){
    this.navCtrl.navigateForward('tabs/ask-question-flow/question-req',{ queryParams: { type: type} })
    //this.navCtrl.navigateForward('tabs/ask-question-flow/condition-check',{ queryParams: { type: type} })
  }
}
