import { utils } from 'protractor';


import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { HttpBase } from '../shared/lib/base-http';
import { NavController, ToastController } from '@ionic/angular';
import { catchError, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Util } from '../shared/lib/util';
const apiHost = environment.apiHost
@Injectable({
  providedIn: 'root'
})
export class ApiService extends HttpBase {

  constructor(public http: HttpClient,
    public toastController: ToastController,
    public navCtrl: NavController) {
    super(http, toastController)
  }





  authLogin() {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    let url = `${apiHost}/auth/login`;
    let data = this.postOb(url, {
      "email": "testerno1.realsun@gmail.com",
      "password": "223456"
    }, options);
    return data
  }


  getAppUserById(id) {
    let url = `${apiHost}/v1/getAppUserByPage?id_eq=${id}&status_eq=10`
    return this.getOb(url)
  }

  getQAByPage(page, limit, sort?) {
    let url = `${apiHost}/v1/getQAByPage?`
    url += `_page=${page}&_limit=${limit}&status_eq=10&isPrivate_eq=0`
    if (sort) {
      url += `&_sort=${sort}&_order=DESC`
    }
    else {
      url += `&_sort=firstTimestamp&_order=DESC`
    }

    // return this.authLogin().pipe(mergeMap(data => {
    // let token = data['data']['token']
    // let options = {
    //   headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` })
    // };
    return this.getOb(url/*, options*/)
    // }))
  }

  getDeptQAByPage(dept, page, limit, sort?) {
    let url = `${apiHost}/v1/getQAByPage?`
    url += `status_eq=10&_page=${page}&_limit=${limit}&status_eq=10&deptCode_eq=${dept}`
    if (sort) {
      url += `&_sort=${sort}&_order=DESC`
    }
    else {
      url += `&_sort=firstTimestamp&_order=DESC`
    }

    return this.getOb(url)
  }

  getQAById(questionId) {
    let url = `${apiHost}/v1/getQAByPage?id_eq=${questionId}&status_eq=10`
    return this.getOb(url)
  }

  getQAByUserId(appUserId, page = 1, limit = 5) {
    let url = `${apiHost}/v1/getQAByPage?appUserId_eq=${appUserId}&status_eq=10`
    url += `&_page=${page}&_limit=${limit}&_sort=firstTimestamp&_order=DESC`
    return this.getOb(url)

  }

  getSearchQAByPage(keyinText, page = 1, limit = 5) {
    let url = `${apiHost}/v1/getQAByPageOR?qaKindName_like=${keyinText}&question_like=${keyinText}
    &answer_like=${keyinText}&tagName_like=${keyinText}`
    url += `&_page=${page}&_limit=${limit}&_sort=firstTimestamp&_order=DESC`
    return this.getOb(url)
  }

  getPostSearchQAByPage(keyinText, body, page = 1, limit = 10) {
    let url = `${apiHost}/v1/getQAByPageOR?`
    let query = `qaKindName_like=${keyinText}&question_like=${keyinText}&title_like=${keyinText}&answer_like=${keyinText}&tagName_like=${keyinText}&_page=${page}&_limit=${limit}&_sort=firstTimestamp&_order=DESC`
    //query=encodeURI(query)
    console.log('full url', `${url}${query}`)
    //this.apiAuthLogin()
    return this.postOb(`${url}${query}`, body)
  }
  getQAAssignedDoctorList(qamId) {
    let url = `${apiHost}/v1/getQAAssignedDoctorList/${qamId}`;
    return this.getOb(url)
  }

  getUserByRoleName(body) {
    let url = `${apiHost}/crud/role/getUserByRoleName`

    return this.postOb(url, body)

  }

  getSearchDoctorByPage(keyinText, page = 1, limit = 5) {
    let url = `${apiHost}/v1/getDoctorByPageOR?name_like=${keyinText}&deptName_like=${keyinText}&detailTypeStr_like=${keyinText}&detailTitle_like=${keyinText}&detailDesc_like=${keyinText}`
    //this.apiAuthLogin()
    url += `&_page=${page}&_limit=${limit}&_sort=name&_order=DESC`
    return this.getOb(url)
  }
  // getSearchColumnByPage(keyinText, page = 1, limit = 5) {
  //   let url = `${apiHost}/v1/getDrColumnByPageOR?title_like=${keyinText}&tagName_like=${keyinText}`
  //   url += `&_page=${page}&_limit=${limit}&_sort=timestamp&_order=DESC`
  //   return this.getOb(url)
  // }

  //   getSearchColumnByPage(keyinText, page = 1, limit = 5) {
  //   let url = `${apiHost}/v1/getDrColumnByPageOR?title_like=${keyinText}&tagName_like=${keyinText}`
  //   url += `&_page=${page}&_limit=${limit}&_sort=timestamp&_order=DESC`
  //   return this.getOb(url)
  // }
  getSearchColumnByPage(keyinText, page = 1, limit = 5){
    let url = `${apiHost}/v1/getDrColumnByPageOR?`
    let query = `title_like=${keyinText}&tagName_like=${keyinText}&_page=${page}&_limit=${limit}&_sort=timestamp&_order=DESC`
    //query=encodeURI(query)
      const current =   Util.getDateString(new Date(),'yyyy-MM-DD HH:mm:ss');
      console.log('current', Util.getDateString(new Date(),'yyyy-MM-DD HH:mm:ss') )
    //query += `&publishFirstTime_lt=${current}`
    console.log('full url', `${url}${query}`)
    //this.apiAuthLogin()
    const body ={prerequisite:`status = 10 and publishFirstTime  < GETDATE()`}
    return this.postOb(`${url}${query}`, body)
  }


  getSearchHealthEduByPage(keyinText, page = 1, limit = 5) {

    let url = `${apiHost}/v1/getHealthEduByPageOR?title_like=${keyinText}&tagName_like=${keyinText}`
    //this.apiAuthLogin()
    url += `&_page=${page}&_limit=${limit}&_sort=timestamp&_order=DESC`
    return this.getOb(url)
  }

  getDoctorByPage(page, limit, doctorId?) {
    let url = `${apiHost}/v1/getDoctorByPage?`

    if (doctorId) {
      url += `id_eq=${doctorId}&`
    }
    else{
      url += `id_neq=${31}&`//平台管理員
      //url += `id_neq=${33}&`//指派請選我
    }
    url += `status_eq=10&`
    url += `_page=${page}&_limit=${limit}&_sort=timestamp&_order=ASC`
    return this.getOb(url)
  }

  getDrColumnByPage(doctorId?,isActive=true,isPublish=true) {
    let url = `${apiHost}/v1/getDrColumnByPage?_page=1&_limit=15&_sort=publishFirstTime&_order=DESC&${isActive?'status_eq=10':''}`
    if (doctorId) {
      url += `&doctorId_eq=${doctorId}`
    }
    if(isPublish){
      const current =   Util.getDateString(new Date(),'yyyy-MM-DD HH:mm:ss');
      url += `&publishFirstTime_lt=${current}`
    }
    return this.getOb(url)
  }

  getDrColumnById(id,isActive=true) {
    console.log('isActive', isActive)
    let url = `${apiHost}/v1/getDrColumnByPage?${isActive?'status_eq=10&':''}_page=1&_limit=1&_sort=timestamp&_order=DESC`
    if (id) {
      url += `&id_eq=${id}`
    }
    return this.getOb(url)
  }

  getHealthEduByPage(doctorId?) {
    let url = `${apiHost}/v1/getHealthEduByPage?status_eq=10&_page=1&_limit=10&_sort=timestamp&_order=DESC`
    if (doctorId) {
      url += `&doctorId_eq=${doctorId}`
    }
    return this.getOb(url)
  }

  getHealthEduById(id) {
    let url = `${apiHost}/v1/getHealthEduByPage?status_eq=10&_page=1&_limit=1&_sort=timestamp&_order=DESC`
    if (id) {
      url += `&id_eq=${id}`
    }
    return this.getOb(url)
  }



  getBlob(blobId) {
    let url = `${apiHost}/v1/getBlob/${blobId}`
    return this.getOb(url)
  }

  getSymptomOptions() {
    let url = `${apiHost}/v1/getSymptomOptions`
    return this.getOb(url)
  }

  getCategoryOptionsByType(type) {
    let url = `${apiHost}/v1/getCategoryOptionsByType/${type}`
    return this.getOb(url)
  }

  getSymptomOptionsByCategoryId(categoryId) {
    let url = `${apiHost}/v1/getSymptomOptionsByCategoryId/${categoryId}`
    return this.getOb(url)
  }

  getCategoryOptions() {
    let url = `${apiHost}/v1/getCategoryOptions?_page=1&_limit=10&_sort=timestamp&_order=DESC`
    return this.getOb(url)
  }
  getNhiDeptOptions() {
    let url = `${apiHost}/v1/getNhiDept`
    return this.getOb(url)
  }

  getDrColMessageByPage(doctorColumnId, page, limit) {
    console.log('doctorColumnId', doctorColumnId)

    let url = `${apiHost}/v1/getDrColMessageByPage/${doctorColumnId}?doctorColumnId_eq=${doctorColumnId}&_page=${page}&_limit=${limit}`
    url += `&_sort=timestamp&_order=DESC`
    return this.getOb(url)
  }
  addDrColMessage(body) {
    let url = `${apiHost}/v1/addDrColMessage`

    return this.postOb(url, body)

  }
  delDrColMessage(body) {
    let url = `${apiHost}/v1/delDrColMessage`

    return this.deleteOb(url,{body})

  }


  getDoctorPhotoById(photoId) {
    let url = `${apiHost}/v1/getDrPhotoByDocId/${photoId}`
    return this.getOb(url)
  }

  updateDoctorColumn(body) {
    let url = `${apiHost}/v1/updateDoctorColumn`

    return this.postOb(url, body)

  }

  updateHealthEducation(body) {
    let url = `${apiHost}/v1/updateHealthEducation`

    return this.postOb(url, body)

  }



  appUserLogin(body) {
    let url = `${apiHost}/v1/appUserLogin`

    return this.postOb(url, body)

  }

  saveAppUser(body) {
    let url = `${apiHost}/v1/saveAppUser`
    //let url =`${apiHost}/v1/saveAppUser`
    return this.postOb(url, body,)
  }
  verifyAppUser(token) {
    let url = `${apiHost}/v1/verifyAppUser/${token}`
    return this.getOb(url)
  }

  checkRegisterInfo(body) {
    let url = `${apiHost}/v1/checkRegisterInfo`
    return this.postOb(url, body)
  }

  sendResetMail(body) {
    let url = `${apiHost}/v1/sendResetMail`
    return this.postOb(url, body)
  }

  sendMail(body) {
    let url = `${apiHost}/v1/sendMail`
    return this.postOb(url, body)
  }

  saveCollection(body) {
    let url = `${apiHost}/v1/saveCollection`
    return this.postOb(url, body)
  }

  saveAppUserExtData(body) {
    let url = `${apiHost}/v1/saveAppUserExtData`
    return this.postOb(url, body)
  }

  insQaM(body) {
    let url = `${apiHost}/v1/insQaM`
    return this.postOb(url, body)
  }

  updQaM(body) {
    let url = `${apiHost}/v1/updQaM`
    return this.postOb(url, body)
  }

  delQaM(id) {
    let url = `${apiHost}/v1/delQaM/${id}`
    const body={id}
    return this.deleteOb(url,{body})
  }

  insQaTag(body) {
    let url = `${apiHost}/v1/insQaTag`
    return this.postOb(url, body)
  }

  saveQaQuestioner(body) {
    let url = `${apiHost}/v1/saveQaQuestioner`
    return this.postOb(url, body)
  }

  /************************* */
  delCollection(id) {
    let url = `${apiHost}/v1/delCollection/${id}`
    let body = { id: id }
    //this.apiAuthLogin()
    return this.deleteOb(url,{body})
  }

  delQaTag() {
    let url = `${apiHost}/v1/delQaTag`
    //this.apiAuthLogin()
    return this.deleteOb(url)
  }

  delDrColumn(id) {
    let url = `${apiHost}/v1/delDrColumn/${id}`
    let body = {  id }
    //this.apiAuthLogin()
    return this.deleteOb(url,{body})
  }

  delDrHealthEdu(id) {
    let url = `${apiHost}/v1/delDrHealthEdu/${id}`
    let body = { id: id }
    //this.apiAuthLogin()
    return this.deleteOb(url,{body})
  }

  getFollowedDoctorsById(appUserId) {
    if (appUserId && appUserId != 0) {
      let url = `${apiHost}/v1/getFollowedDoctorsById/${appUserId}`
      //this.apiAuthLogin()
      return this.getOb(url)
    }
    else {
      return of('error');
    }
  }
  getAppUserExtDataKind() {
    let url = `${apiHost}/v1/getAppUserExtDataKind`
    return this.getOb(url)
  }
  getAppUserExtDataByAppuserId(appUserId) {
    let url = `${apiHost}/v1/getAppUserExtDataByAppuserId/${appUserId}`
    return this.getOb(url)
  }

  saveResetPwd(body) {
    let url = `${apiHost}/v1/saveResetPwd`
    return this.postOb(url, body)
  }

  saveAppUserPwd(body) {
    let url = `${apiHost}/v1/saveAppUserPwd`
    return this.postOb(url, body)
  }
  delCertToken(id) {
    let url = `${apiHost}/v1/delCertToken/${id}`
    //this.apiAuthLogin()
    return this.deleteOb(url)
  }


  addAppUserCert(body) {
    let url = `${apiHost}/v1/addAppUserCert`
    return this.postOb(url, body)
  }
  checkToken(token) {
    let url = `${apiHost}/v1/checkToken/${token}`
    return this.getOb(url)
  }
  /////////////////// ting ///////////////////

  saveDrDetail(body) {
    let url = `${apiHost}/v1/saveDrDetail`
    return this.postOb(url, body)
  }



  saveDrPhoto(body) {
    let url = `${apiHost}/v1/saveDrPhoto`
    return this.postOb(url, body)
  }

  getNhiDept() {
    let url = `${apiHost}/v1/getNhiDept`
    return this.getOb(url)
  }

  saveDoctor(body) {
    let url = `${apiHost}/v1/saveDoctor`
    return this.postOb(url, body)
  }

  getQAByPageByQuery(query?, isAnd = true) {
    let url = isAnd ? `${apiHost}/v1/getQAByPage?${query}&_sort=firstTimestamp&_order=DESC` : `${apiHost}/v1/getQAByPageOR?${query}&_sort=firstTimestamp&_order=DESC`
    return this.getOb(url)
  }

  insQad(body) {
    let url = `${apiHost}/v1/insQad`
    return this.postOb(url, body)
  }
  addQaAssignedDoctor(body) {
    let url = `${apiHost}/v1/addQaAssignedDoctor`
    return this.postOb(url, body)
  }
  getAppUserNotyByPage(query?) {
    let url = `${apiHost}/v1/getAppUserNotyByPage?${query}`
    return this.getOb(url)
  }

  getAppUserUnReadNoty(query?) {
    let url = `${apiHost}/v1/getAppUserNotyByPage?${query}&status_eq=10`
    return this.getOb(url)
  }

  setAppUsrNotyRead(body) {
    let url = `${apiHost}/v1/setAppUsrNotyRead/${body.id}`
    if (body?.id) {
      return this.postOb(url, body)
    }
    else {
      return of(undefined)
    }
  }


  saveDrColumn(body) {
    let url = `${apiHost}/v1/saveDrColumn`
    return this.postOb(url, body)
  }

  setDrColumnPublishTime(body) {
    let url = `${apiHost}/v1/setDrColumnPublishTime`
    return this.postOb(url, body)
  }
  insDrColumnExt(body) {
    let url = `${apiHost}/v1/insDrColumnExt`
    return this.postOb(url, body)
  }

  delDrColumnExt(id) {
    let url = `${apiHost}/v1/delDrColumnExt/${id}`
    let body = { id: id }
    //this.apiAuthLogin()
    return this.deleteOb(url,{body})
  }

  insHealthEduExt(body) {
    let url = `${apiHost}/v1/insHealthEduExt`
    return this.postOb(url, body)
  }
  delHealthEduExt(id) {
    let url = `${apiHost}/v1/delHealthEduExt/${id}`
    let body = { id: id }
    //this.apiAuthLogin()
    return this.deleteOb(url,{body})
  }

  saveHealthEdu(body) {
    let url = `${apiHost}/v1/saveHealthEdu`
    return this.postOb(url, body)
  }
  uploadfileToNextcloud(body, doctorId?, doctorName?) {
    let url = `${apiHost}/v1/uploadfileToNextcloud`
    // let url =`${apiHost}/v1/uploadfileToNextcloud`
    return this.authLogin().pipe(mergeMap(data => {
      let token = data['data']['token']
      let options = {
        headers: new HttpHeaders({
          // 'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
          // __realsun_doctorId: doctorId,
          // __realsun_doctorName: doctorName,
          multerUploadType: 'DRQA',
        }),
        // reportProgress: true,
        // observe: 'events'
      };
      // return this.postOb(url, body, options)
      return this.post(url, body, options)
    }))
  }
  getAppUserPhotoByUsrId(appUserId) {
    let url = `${apiHost}/v1/getAppUserPhotoByUsrId/${appUserId}`
    return this.getOb(url)
  }
  saveDrColumnTag(body) {
    let url = `${apiHost}/v1/saveDrColumnTag`
    return this.postOb(url, body)
  }
  delDrColumnTag(body) {
    let url = `${apiHost}/v1/delDrColumnTag`
    //this.apiAuthLogin()
    return this.deleteOb(url,{body})
  }
  saveHealthEduTag(body) {
    let url = `${apiHost}/v1/saveHealthEduTag`
    return this.postOb(url, body)
  }
  delHealthEduTag(body) {
    let url = `${apiHost}/v1/delHealthEduTag`
    //this.apiAuthLogin()
    return this.deleteOb(url)
  }
  saveAppUserPhoto(body) {
    let url = `${apiHost}/v1/saveAppUserPhoto`
    return this.postOb(url, body)
  }

  getAdvertisementsLastUpdateTime() {
    let url = `${apiHost}/v1/getAdvertisementsLastUpdateTime`
    return this.getOb(url)
  }

  getAdvertisements() {
    let url = `${apiHost}/v1/getAdvertisements`
    return this.getOb(url)
  }

  drMarkRead(id) {

    let url = `${apiHost}/v1/drMarkRead/${id}`
    let body = { id: id }
    //this.apiAuthLogin()
    return this.postOb(url, body)
  }

  addViewCount(body) {
    let url = `${apiHost}/v1/addViewCount`
    return this.postOb(url, body)
  }
}
