import * as moment from "moment";
import { cloneDeep } from 'lodash';
import * as CryptoJS from 'crypto-js'
import { Observable } from "rxjs";


export class Util {


  static isNull(obj) {
    return obj === undefined || obj === null;
  }

  // static isEmptyObj = obj => Util.isNull(obj) || obj === {} || JSON.stringify(obj) === '{}' || obj === "";
  static isEmptyObj = obj => Util.isNull(obj) || obj === {} || obj === '';
  /**
     * 回傳值:
     *  是: 沒有值或 不是陣列, 若為陣列則為空陣列
     *  否: 代表是有值的陣列
     */
  static isEmpty = array => !(array && Array.isArray(array) && array.length > 0);

  /**
 * 取得日期時間的字串
 * date: 日期時間
 * format: 格式 EX: YYYY-MM-DD
 */
  public static getDateString(date, format) {
    format = format || 'YYYY-MM-DD';
    return moment(date).format(format);
  }
  public static isLocalDate(date) {
    return moment(date).isLocal();
  }
  public static dbDateToLocal(date) {
    let aMoment = moment(date);
    let fix = aMoment.utcOffset();
    return aMoment.subtract(fix, 'minutes').toDate();
  }
  public static groupBy(array: Array<any>, key) {
    return array.reduce((rv,x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    },{});
  };

  public static showMessage(toastCtrl, msg, duration?) {
    if (toastCtrl == null) return;
    let toast = toastCtrl.create({
      message: msg,
      duration: duration || 3000,
      position: 'bottom'
    });
    toast.present();
  }
  /**
   * 詢問視窗: 回傳值 true:OK
   * @param alertCtrl
   * @param title
   * @param msg
   * @param okCb
   * @param cancelCb
   * @param okText
   * @param cancelText
   */
  public static confirm(alertCtrl, title, msg, okText?, cancelText?): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let alert = alertCtrl.create({
        title: title,
        message: msg,
        buttons: [
          {
            text: cancelText || "Cancel",
            role: 'cancel',
            handler: () => {

              resolve(false);
            }
          },
          {
            text: okText || "OK",
            handler: () => {

              resolve(true);
            }
          }
        ]
      });
      alert.present();
    });

  }

  // 有含 data:image/jpeg...
  static rotateBase64Image = base64data => {
    return new Promise((resole, reject) => {
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");

      var image = new Image();
      image.src = base64data;
      image.onload = function () {
        ctx.translate(image.width, image.height);
        ctx.rotate(180 * Math.PI / 180);
        ctx.drawImage(image, 0, 0);
        resole(canvas.toDataURL());
        //window.eval("" + callback + "('" + canvas.toDataURL() + "')");
      };
    });
  }



  static isNumber(value) {
    let ret = !isNaN(value)
    return ret;
  }

  static changeCode(value) {
    let changeCode = { A: 10, B: 11, C: 12, D: 13, E: 14, F: 15, G: 16, H: 17, I: 34, J: 18, K: 19, L: 20,
       M: 21, N: 22, O: 35, P: 23, Q: 24, R: 25, S: 26, T: 27, U: 28, V: 29, W: 32, X: 30, Y: 31, Z: 33 }
    return changeCode[value]
  }

  public static isValidIdNo(value): boolean {
    let specificCode = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1];
    //將一般字串轉成陣列
    let identityArr = Array.from(value);
    //加總數直
    let totalNumber = 0;

    for (let i = 0; i < identityArr.length; i++) {
      let countValue = 0;
      if (this.isNumber(identityArr[i])) {
        countValue = +identityArr[i]*specificCode[i+1]
      }
      else {
        if (i == 0||i == 1)
        {
          this.changeCode(identityArr[i])
          let amount= (Math.floor(this.changeCode(identityArr[i])/10) *specificCode[i])+(this.changeCode(identityArr[i])%10*specificCode[i+1])
          countValue=amount

        }
        else {
          let amount =this.changeCode(identityArr[i]) % 10
          totalNumber += countValue * specificCode[i+1]
        }
      }
      totalNumber+=countValue;
    }

    if (totalNumber % 10 == 0) {
      return true;
    }
    else {
      return false;
    }
  }

  public static cloneDeep(o) {
    return cloneDeep(o);
  }


  public static isString(str){
    return (typeof str ==="string") ||   (str instanceof String);

  }
  /**
   * 是否為 JSON 字串
   * @param str
   */
  public static isJsonString(str){
    let isStr = (typeof str ==="string") ||   (str instanceof String);
    if (!isStr) {return false};
    try {
      let obj = JSON.parse(str);
    } catch(e){
      isStr =false;
    }
    return isStr;
  }

  static isBase64Str(str){
    let  base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    return base64regex.test(str);
  }
  static base64ToStr (b64str) {
    var wordArray = CryptoJS.enc.Base64.parse(b64str)
    return  CryptoJS.enc.Utf8.stringify(wordArray);
  };

  public static readFile$(blob: File | Blob, isText = true, isDataUrl = false) {
    return Observable.create(obs => {
      if (!(blob instanceof Blob)) {
        obs.error(new Error('`blob` must be an instance of File or Blob.'));
        return;
      }

      const reader = new FileReader();

      reader.onerror = err => obs.error(err);
      reader.onabort = err => obs.error(err);
      reader.onload = () => obs.next(reader.result);
      reader.onloadend = () => obs.complete();

      isText ? isDataUrl ? reader.readAsDataURL(blob) : reader.readAsText(blob)
        : reader.readAsArrayBuffer(blob);
    });
  }

  public static readFileDataUrl$(blob: File | Blob) {
    return this.readFile$(blob, true, true);
  }
  public static async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }
}
