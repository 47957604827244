import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuController, NavController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { BaseHeaderComponent } from 'src/app/shared/components/base-header/base-header.component';
import { BasePage } from 'src/app/shared/lib/base-page';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent extends BasePage implements OnInit {

  constructor(    public navCtrl:NavController,
    public router:Router,
    public route:ActivatedRoute,
    private menu: MenuController,
    private api:ApiService) {
      super(navCtrl, router, route)
     }

  ngOnInit() {

  }

}
