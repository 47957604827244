
import { Inject, InjectionToken, NgModule } from '@angular/core';
import { BrowserModule, } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ApiService } from './services/api.service';


import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserService } from './services/user.service';
import { IonicStorageModule } from '@ionic/storage-angular';
import { FormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { TokenInterceptor } from './token.interceptor';
import { NgxSpinnerModule } from "ngx-spinner";

import * as MyCustomerCKEditor from  '@realsun/rx-nxc-ckeditor/3rd/build/ckeditor.js'
import { RxNxcCkeditorModule } from '@realsun/rx-nxc-ckeditor';
import { ChatroomModule } from './chatroom/chatroom.module';
export const EnvironmentToken = new InjectionToken('ENVIRONMENT');

declare let gtag: Function;

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    FormsModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    AppRoutingModule,SocialLoginModule,
    NgxSpinnerModule,
    RxNxcCkeditorModule.forRoot(MyCustomerCKEditor),
    ChatroomModule
    ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ApiService,UserService,
    { provide: EnvironmentToken, useValue: environment },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: 'SocialAuthServiceConfig',

      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '1062661982748-mjnmqoq201htn1lm6kpgdcqlummfoorn.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('881507049066316')
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(@Inject(EnvironmentToken) private env: any) {

    gtag('config', this.env.google.GA_TRACKING_ID);
  }
}
