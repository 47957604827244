import { HttpClient } from '@angular/common/http';
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { from, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, mergeMap, concatMap } from 'rxjs/operators';
const apiHost = environment.apiHost;
const apiToken = environment.apiToken;
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private http:HttpClient) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(request.url.startsWith('https://hvdhealth.duckdns.org:8901/drqa/webapi/auth/login')
    ||request.url.startsWith('https://hvdhealth.duckdns.org:8901/drqa/webapi/v1/uploadfileToNextcloud')
    ||request.url.startsWith('https://hvdhealth.duckdns.org:8901/drqa/webapi/v1/ckeditor-img-upload'))
    {

      return  next.handle(request.clone());
    }
    // else if(request.url.startsWith('https://hvdhealth.duckdns.org:8901/drqa/webapi/v1/uploadfileToNextcloud')) {
    //   let options = {
    //     headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    //   };
    //   let url = `${apiHost}/auth/login`;
    //   return this.http.post(url, {
    //     "email": "testerno1.realsun@gmail.com",
    //     "password": "223456"
    //   }, options)
    //   .pipe(mergeMap(data=>{
    //     return next.handle(request.clone({
    //       setHeaders: {
    //         'Access-Control-Allow-Origin': '*',
    //         'Access-Control-Allow-Credentials': 'true',
    //         'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, PATCH',
    //         'Access-Control-Max-Age': '86400',
    //         multerUploadType: 'DRQA',
    //         Authorization: `Bearer ${data['data']['token']}`,
    //       },
    //     }));
    //   }))
    // }
    else if(
      request.url.startsWith('http://60.248.25.238:15005')
    ){
      return next.handle(request.clone({
        setHeaders: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, PATCH',
          'Access-Control-Max-Age': '86400',
          'rejectUnauthorized': 'false',
          'requestCert': 'false',
          'insecure': 'true',
          Authorization: 'Basic ' + btoa('demo:demodemo')
        },
      }));
    }
    else{

        return next.handle(request.clone({
          setHeaders: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, PATCH',
            'Access-Control-Max-Age': '86400',
            'rejectUnauthorized': 'false',
            'requestCert': 'false',
            'insecure': 'true',
            Authorization: `${apiToken}`,
          },
        }));
      }
    }




    // return   next.handle(request.clone({
    //   setHeaders: {
    //     'Access-Control-Allow-Origin': '*',
    //     'Access-Control-Allow-Credentials': 'true',
    //     'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, PATCH',
    //     'Access-Control-Max-Age': '86400',
    //     Authorization: "testba",
    //   },
    // }));

}
