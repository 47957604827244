import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-doctor-picker',
  templateUrl: './doctor-picker.component.html',
  styleUrls: ['./doctor-picker.component.scss'],
})
export class DoctorPickerComponent implements OnInit {
  @Input()  selectedDoctor;
  @Output() onSelectedDoctor = new EventEmitter();
  displayModal=false;
  doctors=[]

  constructor(
    private api:ApiService
  ) { }

  ngOnInit() {
    this.getDoctorsList();
  }
  showModalDialog() {
    this.displayModal = true;
  }
  getDoctorsList(){
    this.api.getDoctorByPage(1,99).subscribe(doctors=>{
      this.doctors = this.doctorDataHandler(doctors['data-key']);
      console.log('this.doctors', this.doctors)
    })
  }
  doctorDataHandler(doctors){
    return doctors.map(doctor=>{
      let photo = this.getDoctorPhoto(doctor.id)
      let detail =doctor?.detail?JSON.parse(doctor.detail):[]
      let subTitle = detail.filter(data=>data.type ==5).length>0?(detail.filter(data=>data.type ==5)[0]['desc']):''
      return {...doctor,
        doctorPhoto:photo
        ,subTitle}
    })
  }
  getDoctorPhoto(doctorId){
    return  this.api.getDoctorPhotoById(doctorId).pipe(map(data=>{
      if(data&&data['data']&&data['data'][0]){
        return (data['data'].filter(data=>{return data.kind ==1}))[0]['photo']
      }
      else{
        return ""
      }
    }))
  }
  selectedDoctorChange(event){
    this.onSelectedDoctor.emit(this.selectedDoctor)
  }
}
