import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-health-education-carousel',
  templateUrl: './health-education-carousel.component.html',
  styleUrls: ['./health-education-carousel.component.scss'],
})
export class HealthEducationCarouselComponent implements OnInit {
  @Input() doctorId;
  @Input() headerText="專欄"
  numVisible = 1;
  healthEducations = []
  constructor(

    private api: ApiService,
    private breakpointObserver: BreakpointObserver,
    private navCtrl: NavController
  ) {
    breakpointObserver.observe([Breakpoints.Small, Breakpoints.Medium]).subscribe(result => {
      if (result.matches) {
        this.numVisible = 2
      }
    });
    breakpointObserver.observe([Breakpoints.Large, Breakpoints.XLarge]).subscribe(result => {
      if (result.matches) {
        this.numVisible = 3
      }
    });
    breakpointObserver.observe([Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).subscribe(result => {
      if (!result.matches) {
        this.numVisible = 1
      }
    });
  }

  ngOnInit() {
    this.doctorId ? this.getHealthEduByDoctorId() : this.getHealthEdu()
    // this.api.getHealthEduByPage().subscribe(educations=>{
    //   this.healthEducations = educations['data-key']
    // })
  }

  articleClick(event, education) {
    this.navCtrl.navigateRoot(`tabs/article/healthEdu/${education.id}`)
  }
  getHealthEduByDoctorId() {
    this.api.getHealthEduByPage(this.doctorId).subscribe(healthEducations => {
      this.healthEducations = healthEducations['data-key']
      this.healthEducations = this.educationsDataHandler(healthEducations['data-key'])
    })
  }
  getHealthEdu() {
    this.api.getHealthEduByPage().subscribe(healthEducations => {
      this.healthEducations = this.educationsDataHandler(healthEducations['data-key'])
    })
  }

  educationsDataHandler(educations) {
    return educations.map(education => {
      let ext;
      let tags;
      let cover;
      let desc;
      desc = (education.desc).replace(/<\/?[^>]+(>|$)/g, "");
      if (education.ext) {
        ext = JSON.parse(JSON.parse(JSON.stringify(education.ext)))
        cover = ext.filter(data => data.kind == 20).length > 0 ? `${ext.filter(data => data.kind == 20)[0]['blobPath']}/preview` : "assets/images/1.jpeg"
      }
      if (education.tags) {
        tags = JSON.parse(JSON.parse(JSON.stringify(education.tags)))
      }
      return {
        doctorPhoto: this.getDoctorPhoto(education.doctorId), cover: cover ? cover : "assets/images/1.jpeg", ...education, desc: desc, tags: tags
      }
    })
  }
  goToDoctorInfo(event, doctorId) {
    event.stopPropagation()
    this.navCtrl.navigateRoot(`tabs/doctor-info/${doctorId}`);
  }
  getDoctorPhoto(doctorId) {
    return this.api.getDoctorPhotoById(doctorId).pipe(map(data => {
      if (data && data['data'] && data['data'][0]) {
        return (data['data'].filter(data => { return data.kind == 1 }))[0]['photo']
      }
      else {
        return ""
      }
    }))
  }
  goToHealthEduDashBoard(event){
    event.stopPropagation()
   this.navCtrl.navigateRoot(`tabs/health-education-dashboard`);
  }
  healthEducationTagsClick(event,tagName){
    event.stopPropagation();
    this.navCtrl.navigateForward('tabs/health-education-dashboard',{ queryParams: { keyinText:tagName} })
    }
}
