import { Observable, from, of, throwError, timer } from 'rxjs';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';



import { ToastController, NavController } from '@ionic/angular';
import { catchError, delayWhen, first, map, retry, retryWhen, tap } from 'rxjs/operators';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Util } from './util';
export { BaseHttp as HttpBase };

@Injectable()
class BaseHttp {
  protected http: HttpClient;
  protected toastCtrl: ToastController;
  constructor(
    http: HttpClient,
    toastCtrl: ToastController,
  ) {
    this.http = http;
    this.toastCtrl = toastCtrl;
  }


  protected async get(url: string, options?): Promise<any> {
    let res;
    try {
      if (options) {
        res = await this.http.get(url, options)
          .toPromise();
      }
      else {
        res = await this.http.get(url)
          .toPromise();
      }

    } catch (error) {

      this.presentToast(`錯誤:${url}-${error}`);
    }
    // return res === null ? null : res.json();
    let hasJsonFunction =
      res !== undefined && res !== null && typeof res.json === 'function';
    return hasJsonFunction ? res.json() : res;

  }

  protected  getRequest(url: string, options?){
    let res;
      if (options) {
        res =  this.http.get(url, options)
      }
      else {
        res =  this.http.get(url)
      }
      return res.pipe(
        first(),
        retry(2),
        catchError(this.handleError)
      )
  }

  protected getOb(url: string, options?): Observable<any> {
    let res;
    try {
      if (options) {
        return this.http.get(url, options).pipe(
          first(),
          retry(2),

          // retryWhen(errors =>
          //   errors.pipe(
          //     delayWhen(val => timer(7000))
          //   )
          // ),
          catchError(this.handleError)
        );
      }
      else {
        return this.http.get(url).pipe(
          first(),
          retry(2),

          catchError(this.handleError)
        );
      }
    } catch (error) {
      throwError(error)
      this.presentToast(`錯誤:${url}-${error}`);
    }
  }

  protected deleteOb(url: string, option?: any): Observable<any> {
    console.log('option', option)
    return this.http.delete(url, option).pipe(
      retry(2),
      first(),
      catchError(e => { this.presentToast(`錯誤:${url}-${e}`); return of(e); this.handleError })
    );
  }

  protected async post(url: string, request: any, options?): Promise<any> {
    let res;
    try {
      if (options) {
        res = await this.http.post(url, request, options).toPromise();
      }
      else {
        res = await this.http.post(url, request).toPromise();
      }

    } catch (error) {
      console.log(error);
      // if (this.messageService)
      //   this.messageService.add({
      //     severity: "error",
      //     summary: "錯誤:",
      //     detail: error
      //   });
      this.presentToast(`錯誤:${url}-${error}`);
    }
    console.log('base post:', res);
    //  return res === null ? null : res.json();
    let hasJsonFunction =
      res !== undefined && res !== null && typeof res.json === 'function';
    return hasJsonFunction ? res.json() : res;
  }

  protected  postRequest(url: string,request: any, options?)  {
    let res;
      if (options) {
        res =  this.http.post(url, request, options);
      }
      else {
        res =  this.http.post(url, request);
      }
    return res.pipe(
      first(),
      retry(2),
      catchError(this.handleError)
    );
  }

  protected postOb(url: string, request: any, options?: any): Observable<any> {

    return (
      this.http
        // .post(url, JSON.stringify(request), this.requestOptions)
        .post(url, JSON.stringify(request), options ? options : {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        })
    ).pipe(
      first(),
      retry(2),
      catchError(this.handleError)
    );
  }

  protected async delete(url: string, options?: any): Promise<any> {
    let res;
    try {
      res = await this.http.delete(url, options).toPromise();
    } catch (error) {

      // if (this.messageService)
      //   this.messageService.add({
      //     severity: "error",
      //     summary: "錯誤:",
      //     detail: error
      //   });
      this.presentToast(`錯誤:${url}-${error}`);
    }
    // return res === null ? null : res.json();
    return res;
  }

  presentToast(msg) {
    if (!this.toastCtrl) return;
    let toast = this.toastCtrl.create({
      message: msg,
      duration: 5000,
      position: 'bottom',
    })
  }

  handleError(error: Response | any) {
    // In a real world app, you might use a remote logging infrastructure
    let errMsg: string;

    if (error instanceof Response || error instanceof HttpErrorResponse) {
      //   const body = error.json() || '';
      //   const err = body.error || JSON.stringify(body);
      let err = '';
      if (error instanceof HttpErrorResponse) {
        if (error.ok === false || error.status < 200 || error.status >= 300) {
          err = '連接後端主機失敗!!';
          let retData = error.error;
          if (!Util.isEmptyObj(retData) && !Util.isEmptyObj(retData.error)) {
            err += retData.error;
          }
        }
      }
      else if (error instanceof Response &&
        (error.ok === false || error.status < 200 || error.status >= 300)
      ) {
        // if (error.ok === false) {
        err = '連接後端主機失敗!!';
      }
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(errMsg);
  }
}
