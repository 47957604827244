// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //domain:"http://localhost:4200",
  domain:"https://www.hvdhealth.com.tw",
  apiHost:"https://hvdhealth.duckdns.org:8901/drqa/webapi",
  //apiHost:"http://localhost:3000/webapi",
  customerCenterEmail:"missionyoung365@gmail.com",
  google: {
    GA_TRACKING_ID: 'G-YGXL9GYY74'
  },
  apiToken:`Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEwMCIsIm5hbWUiOiJBcHDmk43kvZzlk6EiLCJlbWFpbCI6InJlYWxzdW4uNWdoaXNAZ21haWwuY29tIiwicm9sZSI6InVzZXIiLCJhbGxSb2xlcyI6W10sImV4cGlyZXNJbiI6MzE1MzYwMDAwLCJpYXQiOjE2NDQ0NTc3OTksImV4cCI6MTk1OTgxNzc5OX0.bgpFpBXMJlulCt8WcR3X4eCwATC-OakkNa6MCk4vYJDp0DakxBBQPJyYSA7GWpQrFd8VT-Nnrlry5j71Ju3KQyyWNTh7ZvundynDVb0xGXcraa4-rhiYOQnx3DWMVByXG5P2HfYRkNXPCEofVjC1a8R0xLCJ3ZdlHbM2ICPYK7AS89V1ic5brYIFL4_P_Po13wmZBMZ_rxVHoW9fjZlgrKCNOiPb1jTIhHkcvcbmdBgA5YfysGRVfiVAyeyKnPUI3qtZl481Dge-DedZaRb89LwrIApdJccdem3oRfQRXmDK9A6XHYd-rhlutj09heh6Z8qqSgApw_b9EIU9CO3eCQ`,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
