import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-base-footer',
  templateUrl: './base-footer.component.html',
  styleUrls: ['./base-footer.component.scss'],
})
export class BaseFooterComponent implements OnInit {

  constructor(
    private navCtrl:NavController
  ) { }

  ngOnInit() {}

  goToAbout(section){
    this.navCtrl.navigateRoot('tabs/about',{ queryParams: { section:`${section}`} })
  }

  goToURL(event,url){
    event.stopPropagation()
    this.navCtrl.navigateRoot(`tabs/${url}`)
  }
  requestQuestionClick(type){
    this.navCtrl.navigateForward('tabs/ask-question-flow/question-req',{ queryParams: { type: type} })
  }
}
