import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { distinctUntilChanged } from 'rxjs/operators';
import { UserService } from './services/user.service';
import { Meta, Title } from '@angular/platform-browser';
import { ApiService } from './services/api.service';
declare let gtag: Function;
import Widget from 'rasa-webchat';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent implements OnInit {
  userData$;
  userData;
  socialUserInfo
  socialUserInfo$
  appUserUnReadNoty$
  doctorId;
  constructor( private meta:Meta,public navCtrl: NavController, private storage: Storage,
    private router:Router,    private userService: UserService,private api:ApiService) { }
  async ngOnInit() {
    this.meta.removeTag('name="parsely-link"');
    this.meta.addTag({name: "parsely-link", content: "https://www.hvdhealth.com.tw/tabs/home"});
    this.meta.removeTag('name="description"');
    this.meta.addTag({name:"description",
          content:"專業醫師解答您的疑難雜症，最健全的醫療、衛教知識網站"})
    await this.storage.create();
    this.userData$ = this.userService.userData$
    this.userData$.subscribe(userData => {
      this.userData = userData;
      if (this.userData && this.userData != null && this.userData['id'])
        if (this.userData['doctor']) {
          this.doctorId = this.userData['doctor'] ? JSON.parse(this.userData['doctor'])[0]['id'] : 0
        }
        else {
          this.doctorId = 0
        }
    })

    this.storage.get("userData").then(userData => {
      this.userService.loginHandler(userData)
      if (userData && userData['id']) {
        this.appUserUnReadNoty$ = this.userService.unReadNotyCount$
      }
    })
    this.socialUserInfo$ = this.userService.socialUserInfo$
    this.socialUserInfo$.subscribe(socialUserInfo => {

      if (socialUserInfo)
        this.socialUserInfo = socialUserInfo;
    })

    this.storage.get("socialUserInfo").then(socialUserInfo => {
      this.socialUserInfo = socialUserInfo
      this.userService._socialUserInfo = socialUserInfo;
      this.userService.socialUserInfo$.next(socialUserInfo);
    })

    this.router.events
    .pipe(distinctUntilChanged((previous: any, current: any) => {
      if (current instanceof NavigationEnd) {
        return previous.url === current.url;
      }
      return true;
    }))
    .subscribe(
      (x: any) => { gtag('event', 'page_view', { 'page_path': x.url }); });
  }

  goTo(url) {
    this.navCtrl.navigateRoot(`tabs/${url}`)
  }

  goToReq(url) {
    this.navCtrl.navigateRoot(`tabs/${url}`)
  }

  goToDoctorManager(url) {
    if (this.doctorId && this.doctorId != 0) {

      this.navCtrl.navigateRoot(`tabs/${url}`)
    }
    else {
      this.doctorId = this.userData['doctor'] ? JSON.parse(this.userData['doctor'])[0]['id'] : 0
    }
  }

  goToDoctorRegister(){
    this.navCtrl.navigateRoot(`tabs/dr-management/add-doctor/${this.userData.id}`)
  }

  openHoverClick(event) {
    event.stopPropagation()
    window.open('https://www.missionyoung.com.tw/', "_blank");
  }

  logout(event) {
    this.userService.logout()
  }

}
