import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { BaseHeaderComponent } from './components/base-header/base-header.component';
import { CarouselModule } from 'primeng/carousel';
import { MenuModule } from 'primeng/menu';

import { DoctorsCarouselComponent } from './components/doctors-carousel/doctors-carousel.component';
import { CategoryGridComponent } from './components/category-grid/category-grid.component';
import { LatestQuestionsComponent } from './components/latest-questions/latest-questions.component';
import { HealthEducationCarouselComponent } from './components/health-education-carousel/health-education-carousel.component';
import { DoctorColumnCarouselComponent } from './components/doctor-column-carousel/doctor-column-carousel.component';
import { RequestQuestionComponent } from './components/request-question/request-question.component';
import { HotQuestionsComponent } from './components/hot-questions/hot-questions.component';
import { QuickResponseQuestionsComponent } from './components/quick-response-questions/quick-response-questions.component';
import { RelatedQuestionCarouselComponent } from './components/related-question-carousel/related-question-carousel.component';
import { DoctorListComponent } from './components/doctor-list/doctor-list.component';
import { HeadlinesComponent } from './components/headlines/headlines.component';

import { DateAgoPipe } from './pipes/date-ago.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseFooterComponent } from './components/base-footer/base-footer.component';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { DeptQuestionsComponent } from './components/dept-questions/dept-questions.component';
import { DoctorPickerComponent } from './components/doctor-picker/doctor-picker.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonModule } from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import {ListboxModule} from 'primeng/listbox';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';


const components = [
  SearchBarComponent, BaseHeaderComponent, BaseFooterComponent,
  DoctorsCarouselComponent, CategoryGridComponent,
  LatestQuestionsComponent, HealthEducationCarouselComponent, DoctorColumnCarouselComponent, DoctorPickerComponent,
  RequestQuestionComponent, HotQuestionsComponent, QuickResponseQuestionsComponent,
  RelatedQuestionCarouselComponent, DoctorListComponent, HeadlinesComponent, DeptQuestionsComponent
]
const pipes = [DateAgoPipe, SafeHtmlPipe]

@NgModule({
  declarations: [...components, ...pipes],
  imports: [
    CommonModule, IonicModule, CarouselModule, TableModule,ListboxModule,
     PaginatorModule, MenuModule,ConfirmDialogModule, FormsModule, ReactiveFormsModule,
     DialogModule,
     ButtonModule
  ],
  providers:[ConfirmationService],
  exports: [...components, ...pipes]
})
export class SharedModule { }
