import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-doctor-column-carousel',
  templateUrl: './doctor-column-carousel.component.html',
  styleUrls: ['./doctor-column-carousel.component.scss'],
})
export class DoctorColumnCarouselComponent implements OnInit {
  @Input() doctorId;

  numVisible=1;
  columns=[]
  responsiveOptions;
  constructor(

    private api:ApiService,
    private breakpointObserver:BreakpointObserver,
    private navCtrl:NavController
  ) {

    breakpointObserver.observe([Breakpoints.Small,Breakpoints.Medium]).subscribe(result => {
      if(result.matches){
        this.numVisible=2
      }
    });
    breakpointObserver.observe([Breakpoints.Large,Breakpoints.XLarge]).subscribe(result => {
      if(result.matches){
        this.numVisible=3
      }
    });
    breakpointObserver.observe([Breakpoints.Small,Breakpoints.Medium,Breakpoints.Large,Breakpoints.XLarge]).subscribe(result => {
      if(!result.matches){
        this.numVisible=1
      }
    });
  }

  ngOnInit(){
    this.doctorId?this.getDrColumns():this.getColumns()
  }

  getColumns(){
    this.api.getDrColumnByPage().subscribe(columns=>{
      this.columns = this.columnDataHandler(columns['data-key'])
    })
  }

  getDrColumns(){
    this.api.getDrColumnByPage(this.doctorId).subscribe(columns=>{
      this.columns = this.columnDataHandler(columns['data-key'])
    })
  }

  articleClick(event,column){
    this.navCtrl.navigateRoot(`tabs/article/column/${column.id}`)
  }

  getDoctorPhoto(doctorId){
    return  this.api.getDoctorPhotoById(doctorId).pipe(map(data=>{
      if(data&&data['data']&&data['data'][0]){
        return (data['data'].filter(data=>{return data.kind ==1}))[0]['photo']
      }
      else{
        return ""
      }
    }))
 }
 columnDataHandler(columns){
    return columns.map(column=>{
      let ext;
      let tags;
      let cover;
      let desc;
      desc=(column.desc).replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, ' ');
      if(column.ext){
        ext = JSON.parse(JSON.parse(JSON.stringify(column.ext)))
        cover=ext.filter(data=>data.kind == 20).length>0?`${ext.filter(data=>data.kind == 20)[0]['blobPath']}/preview`:"assets/images/1.jpeg"
      }
      if(column.tags){
        tags = JSON.parse(JSON.parse(JSON.stringify(column.tags)))
      }

      return{
        doctorPhoto:this.getDoctorPhoto(column.doctorId),
        cover:cover?cover:"assets/images/1.jpeg",
        ...column,desc:desc,tags:tags,
        doctorInfo:this.api.getDoctorByPage(1, 10, column.doctorId)

                    .pipe(map(doctors=>{
                      let doctor = doctors['data-key'][0];
                      let detail  =doctor.detail? JSON.parse(JSON.parse(JSON.stringify(doctor.detail))):[];

                      let subTitle = detail.filter(data=>data.type ==5).length>0?(detail.filter(data=>data.type ==5)[0]['desc']):''


                      return {doctorInfo:doctor,subTitle}
                    }))
      }
    })
 }

 goToDoctorInfo(event,doctorId){
   event.stopPropagation()
   this.navCtrl.navigateRoot(`tabs/doctor-info/${doctorId}`);
 }

 goToColumnDashBoard(event){
   event.stopPropagation()
  this.navCtrl.navigateRoot(`tabs/column-dashboard`);
 }
 columnTagsClick(event,tagName){
  event.stopPropagation();
  this.navCtrl.navigateForward('tabs/column-dashboard',{ queryParams: { keyinText:tagName} })
  }

}
