import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavController, ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  @Input() placeholder: string ="搜尋病症、科別、醫師  ex:家醫科、糖尿病";
  _keyinText=""

  @Input() mode="redirect"  //redirect|search;
  @Input() redirectType="all"

  @Input()
  get keyinText() {
    return this._keyinText;
  }
  @Output() onKeyinTextChange = new EventEmitter();
  set keyinText(value) {

    this._keyinText = value;
    this.onKeyinTextChange.emit(this._keyinText);
  }
  keyinTextChange$=new BehaviorSubject("");
  constructor(
    public navCtrl:NavController,
    public toastCtrl:ToastController
  ) { }

  ngOnInit() {}


  onSearchClick(){

    if(this.keyinText&&this.keyinText.length>1)
    {

      if(this.mode=='redirect'){
        switch (this.redirectType) {
          case 'all':
            this.navCtrl.navigateForward('tabs/search-result',{ queryParams: { keyinText: this.keyinText} })
            break;
          case 'doctor':
            this.navCtrl.navigateForward('tabs/docotr-dashboard',{ queryParams: { keyinText: this.keyinText} })
            break;
          case 'column':
            this.navCtrl.navigateForward('tabs/column-dashboard',{ queryParams: { keyinText: this.keyinText} })
            break;
          case 'education':
            this.navCtrl.navigateForward('tabs/education-dashboard',{ queryParams: { keyinText: this.keyinText} })
            break;
          case 'question':
            this.navCtrl.navigateForward('tabs/question-dashboard',{ queryParams: { keyinText: this.keyinText} })
            break;
          default:
            this.navCtrl.navigateForward('tabs/search-result',{ queryParams: { keyinText: this.keyinText} })
            break;
        }

      }
      else{
        this.onKeyinTextChange.emit(this.keyinText)
      }
    }
    else{
      this.toastCtrl.create({
        message: '請至少輸入兩個字',
        duration: 3000
      }).then(toast=>{
        toast.present();
      });
    }
  }

  eventHandler(event){
    //鍵盤enter事件

    if(event==13){
      this.onSearchClick();
    }
  }

  onFocus(event){

  }

  onBlur(event){


    //this.expandContentDisplay=false;
   /* this.onSearchClick();*/
  }
}
