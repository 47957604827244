import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { BreakpointObserver, Breakpoints, MediaMatcher } from '@angular/cdk/layout';
import { NavController } from '@ionic/angular';
import { LazyLoadEvent } from 'primeng/api';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-dept-questions',
  templateUrl: './dept-questions.component.html',
  styleUrls: ['./dept-questions.component.scss'],
})
export class DeptQuestionsComponent implements OnInit {
  @Input() dept;
  @Input() limit: number = 6;
  @Input() columns: number = 3;

  isHandSet: boolean;
  nhiDeptOptions=[];
  displayQuestions = []
  totalRecords;
  loading: boolean;

  constructor(
    private api: ApiService,
    private navCtrl: NavController,
    private breakpointObserver: BreakpointObserver,
  ) {
    breakpointObserver.observe([Breakpoints.HandsetPortrait]).subscribe(result => {
      this.isHandSet = result.matches;
    });
    breakpointObserver.observe([Breakpoints.Medium,Breakpoints.Large]).subscribe(result => {
      if(result.matches){
        this.columns=2
      }
    });
    breakpointObserver.observe([Breakpoints.Large,Breakpoints.XLarge]).subscribe(result => {
      if(result.matches){
        this.columns=3
      }
    });
    breakpointObserver.observe([Breakpoints.Medium,Breakpoints.Large,Breakpoints.XLarge]).subscribe(result => {
      if(!result.matches){
        this.columns=1
      }
    });
  }

  goToQuestionList(event) {
    this.navCtrl.navigateRoot("/tabs/question-dashboard")
  }

  goToQuestionReplys(event, question) {
    this.navCtrl.navigateRoot(`/tabs/question-replys/${question.id}`)
  }

  ngOnInit() {
    this.api.getNhiDeptOptions().subscribe(nhiDeptOptions => {
      this.nhiDeptOptions = nhiDeptOptions.data;
      this.nhiDeptOptions = this.nhiDeptOptions.filter(data => {
        return +(data.order) == 0 && data.order != null
      })
      this.dept = this.nhiDeptOptions[0]['code']
    })
    this.getDeptQAByPage();
  }

  getDeptQAByPage(){
    this.api.getDeptQAByPage(this.dept,1, this.limit).subscribe(questions => {
      this.displayQuestions = this.questionDataHandler(questions['data-key']) ;

      this.totalRecords = questions['x-total-count']
      this.loading = false;
    })
  }

  nhiDeptClick(nhiDept) {
    this.dept=nhiDept.code
    this.getDeptQAByPage()
  }

  paginate(event) {
    let first = event.first
    let page = first == 0 || isNaN(first) ? 1 : (first / this.limit) + 1

    setTimeout(() => {

      this.api.getDeptQAByPage(this.dept,1, this.limit).subscribe(questions => {
        this.displayQuestions = this.questionDataHandler(questions['data-key']) ;
        this.totalRecords = questions['x-total-count']
        this.loading = false;
      })
    }, 1000);
  }

  questionDataHandler(questions){

    return questions.map(question => {
      let title;
      let tags;
      let userPhoto;
                title =question.title&&question.title!==''?question.title:question.question.substring(0,20);
       userPhoto =this.api.getAppUserPhotoByUsrId(question.appUserId).pipe(map(photo=> photo.data.filter(data=>data.kind==1)[0]))
      if(question.tags){
        tags = JSON.parse(JSON.parse(JSON.stringify(question.tags)))
      }
      return {...question,tags:tags,userPhoto:userPhoto,title:title}
    });
  }
  onImgError(event) {
    event.target.src = 'assets/images/1.jpeg';
  }

  questionTagsClick(event,tagName){
    event.stopPropagation();
    this.navCtrl.navigateForward('tabs/question-dashboard',{ queryParams: { keyinText:tagName} })
  }
}
