import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-category-grid',
  templateUrl: './category-grid.component.html',
  styleUrls: ['./category-grid.component.scss'],
})
export class CategoryGridComponent implements OnInit {
  nhiDeptOptions = []
  isHandSet = false;
  @Input() redirectType="question"
  constructor(
    private api: ApiService,
    private breakpointObserver: BreakpointObserver,
    private navCtrl: NavController

  ) {
    breakpointObserver.observe([Breakpoints.HandsetPortrait]).subscribe(result => {
      if (this.isHandSet != result.matches) {
        this.isHandSet = result.matches;
      }
    });
  }

  ngOnInit() {
    // this.api.getNhiDeptOptions().subscribe(nhiDeptOptions => {
    //   console.log('nhiDeptOptions', nhiDeptOptions)
    //   this.nhiDeptOptions = nhiDeptOptions.data;
    //   this.nhiDeptOptions = this.nhiDeptOptions.filter(data => {
    //     return +(data.order) == 0 && data.order != null
    //   })
    // })
    this.nhiDeptOptions=[
      {name:'肝膽腸胃'},
      {name:'心臟血管'},
      {name:'耳鼻喉科'},
      {name:'婦產科'},
      {name:'眼科'},
      {name:'急診醫學科'},
      {name:'營養科'},
      {name:'功能醫學'},
    ]
  }

  nhiDeptClick(nhiDept) {
    switch (this.redirectType) {
      case 'doctor':
        this.navCtrl.navigateRoot('tabs/doctor-dashboard', { queryParams: { keyinText: nhiDept.name } })
        break;
      case 'question':
        this.navCtrl.navigateRoot('tabs/question-dashboard', { queryParams: { keyinText: nhiDept.name } })
        break;
      default:
        break;
    }
  }
  onImgError(event) {
    event.target.src = 'assets/images/1.jpeg';
  }
}
