import { Router } from '@angular/router';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-chatroom',
  templateUrl: './chatroom.component.html',
  styleUrls: ['./chatroom.component.scss'],
})
export class ChatroomComponent implements OnInit {
  items=[]
  display: boolean = true;
  currentFontSize='14px';
  constructor(private render:Renderer2,
    private router:Router,
    private confirmationService: ConfirmationService,
    private storage:Storage
    ) { }

  ngOnInit() {
    const chatroom =document.getElementById('chatroom');
    const chatroomButtonWrapper =document.getElementById('chatroomButtonWrapper');
    this.render.setStyle(chatroom,'visibility','visible');
    this.render.setStyle(chatroomButtonWrapper,'visibility','hidden');
    const chatroomContainer = document.getElementById('chatroomContainer');
    this.render.appendChild(chatroomContainer,chatroom);

    this.storage.get('hasConfirmAgreen').then(hasConfirmAgreen=>{
      if(hasConfirmAgreen&&hasConfirmAgreen==true)
        this.display = false
    })
    this.items = [
      {
          icon: 'pi pi-pencil',
          command: () => {
            this.changeFontSize('1rem');
          }
      },
      {
        icon: 'pi pi-pencil',
        command: () => {
          this.changeFontSize('1.2rem');
        }
      },
      {
        icon: 'pi pi-pencil',
        command: () => {
          this.changeFontSize('1.5rem');
        }
      },
  ];
  }



  showDialog() {
      this.display = true;
  }
  changeFontSize(size){
    const chatroom =document.getElementById('chatroom')
    this.currentFontSize=size;
    this.render.setStyle(chatroom,'font-size',size);
  }
  reject(){
    window.close()
  }
  agree(){
    this.display=false
    this.storage.set('hasConfirmAgreen',true).then()
  }

}
