
import { BreakpointObserver, Breakpoints, MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ResQADataModel } from 'src/app/pages/dr-management/model/dr-management.model';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-doctors-carousel',
  templateUrl: './doctors-carousel.component.html',
  styleUrls: ['./doctors-carousel.component.scss'],

})
export class DoctorsCarouselComponent implements OnInit {
  userData$;
  userData;
  doctors=[]
  userFollowedDoctors=[]
  userFollowedDoctors$;
  isHandSet:boolean=false;
  numVisible=1;
  constructor(
    private api:ApiService,
    private breakpointObserver:BreakpointObserver,
    private navCtrl:NavController,
    private userService:UserService,
  ) {
    breakpointObserver.observe([Breakpoints.Medium,Breakpoints.Large]).subscribe(result => {
      if(result.matches){
        this.numVisible=2
      }
    });
    breakpointObserver.observe([Breakpoints.Large,Breakpoints.XLarge]).subscribe(result => {
      if(result.matches){
        this.numVisible=3
      }
    });
    breakpointObserver.observe([Breakpoints.Medium,Breakpoints.Large,Breakpoints.XLarge]).subscribe(result => {
      if(!result.matches){
        this.numVisible=1
      }
    });
   }

  ngOnInit(){
    this.userData$=this.userService.userData$
    this.userData$
      .subscribe(userData =>{
        if(userData){
          this.userData=userData
          this.userFollowedDoctors$=this.userService.userCollections$
          this.userFollowedDoctors$
            .subscribe(collections =>{
              this.userFollowedDoctors = collections;

            } );
          this.userService.getUserCollection();
          this.api.getFollowedDoctorsById(this.userData.id)
          .subscribe(followedDoctors=>{
            if(followedDoctors&&followedDoctors['data-key']&&followedDoctors['data-key'][0]['followedDoctors']){
              this.userFollowedDoctors= JSON.parse(followedDoctors['data-key'][0]['followedDoctors'])
            }
          })
        }
      });

    this.api.getDoctorByPage(1,10).subscribe(doctors=>{
      this.doctors = doctors['data-key'];
      this.doctors=this.doctors.map(doctor=>{
        let photo = this.getDoctorPhoto(doctor.id)
        let replyCount =this.getDoctorQaReplyCount(doctor.appUserId)
        let columnCount =doctor.column? JSON.parse(JSON.parse(JSON.stringify(doctor.column))).length:0;
        let detail  =doctor.detail? JSON.parse(JSON.parse(JSON.stringify(doctor.detail))):[];
        let specialize = detail.filter(data=>data.type ==4).length>0?(detail.filter(data=>data.type ==4)[0]['desc']).split(',').slice(0,3):[]
        let experience = detail.filter(data=>data.type ==1).length>0?(detail.filter(data=>data.type ==2)[0]['desc']).split(',').slice(0,3):[]
        let infoDesc = detail.filter(data=>data.type ==1).length>0?(detail.filter(data=>data.type ==3)[0]['desc']):''
        let subTitle = detail.filter(data=>data.type ==5).length>0?(detail.filter(data=>data.type ==5)[0]['desc']):''
        //let columnCount = this.getColumnCount(doctor.id)
        return {...doctor,doctorPhoto:photo,replyCount:replyCount,columnCount:columnCount,specialize:specialize,experience:experience,infoDesc,subTitle}
      })
    })
  }
  goToDoctorInfo(event,doctor){
    event.stopPropagation();
    this.navCtrl.navigateRoot(`tabs/doctor-info/${doctor.id}`)
  }
  getDoctorPhoto(doctorId){
    return  this.api.getDoctorPhotoById(doctorId).pipe(map(data=>{
      if(data&&data['data']&&data['data'][0]){
        return (data['data'].filter(data=>{return data.kind ==1}))[0]['photo']
      }
      else{
        return ""
      }
    }))
  }
 isDoctorFollowed(doctorId){
   return  this.userFollowedDoctors&&this.userFollowedDoctors.length>0?this.userFollowedDoctors.some(data=>data.doctorId ==doctorId):false
 }


 followDoctorClick(doctorId){
  this.userService.followDoctor(doctorId)
 }

 unFollowDoctorClick(doctorId){
  this.userService.unFollowDoctor(doctorId)
 }

  getDoctorQaReplyCount(appUserId) {
    let query = `_page=1`
    query += `&qadAppUserId_like=${appUserId}`
    query += `&status_eq=10`

    return this.api.getQAByPageByQuery(query).pipe(map(ret=>{
      return ret['x-total-count']
    }),catchError(error=>{return of(0)}))
  }
  specializeClick(event,specialize){
    event.stopPropagation();
    this.navCtrl.navigateForward('tabs/doctor-dashboard',{ queryParams: { keyinText:specialize} })
  }
  doctorColumnClick(event,doctorId){
    event.stopPropagation();
    //this.navCtrl.navigateForward(`tabs/doctor-info/${doctorId}`)
    this.navCtrl.navigateRoot(`tabs/doctor-info/${doctorId}`,{ queryParams: { tabs:1} })
  }

  askDoctorQuestionClick(event,doctor){
    event.stopPropagation();
    this.navCtrl.navigateForward('tabs/ask-question-flow/question-req',{ queryParams: { type: 'symptom',selectedAssignDoctor:doctor} })
  }
}
