import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChatroomRoutingModule } from './chatroom-routing.module';
import { ChatroomComponent } from './chatroom.component';
import { SpeedDialModule } from 'primeng/speeddial';
import {DialogModule} from 'primeng/dialog';
import { SharedModule } from '../shared/shared.module';
@NgModule({
  declarations: [ChatroomComponent],
  imports: [
    CommonModule,
    SharedModule,
    SpeedDialModule,
    DialogModule,
    ChatroomRoutingModule
  ],
  exports:[ChatroomComponent],

})
export class ChatroomModule { }
