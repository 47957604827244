import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavController, ToastController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-base-header',
  templateUrl: './base-header.component.html',
  styleUrls: ['./base-header.component.scss'],
})
export class BaseHeaderComponent implements OnInit {
  @Input() title: string = "標題";
  @Input() isBackButtonDisplay: boolean = true;
  @Input() isMenuButtonDisplay: boolean = true;
  @Output() goBack = new EventEmitter();
  userData$;
  userData;
  socialUserInfo$;
  socialUserInfo;
   placeholder: string ="搜尋病症、科別、醫師  ex:家醫科、糖尿病";
  _keyinText=""
   mode="redirect"  //redirect|search;
   redirectType="all"
  get keyinText() {
    return this._keyinText;
  }
  @Output() onKeyinTextChange = new EventEmitter();
  set keyinText(value) {

    this._keyinText = value;
    this.onKeyinTextChange.emit(this._keyinText);
  }
  keyinTextChange$=new BehaviorSubject("");
  private items: MenuItem[];
  private healthItems: MenuItem[];
  private reqQuestionItems: MenuItem[];

  constructor(
    private confirmationService: ConfirmationService,
    private navCtrl: NavController,
    private userService: UserService,
    private api: ApiService,
    private toastCtrl:ToastController
  ) { }

  ngOnInit() {
    this.userData$ = this.userService.userData$;
    this.socialUserInfo$ = this.userService.socialUserInfo$;
    this.userData$.subscribe(userData => {
      if (userData && userData?.id) {
        this.api.getAppUserById(userData.id).subscribe(userData => {
          this.userData = userData['data-key'][0]

          let photo =this.api.getAppUserPhotoByUsrId(this.userData?.['id']).pipe(map(ret=>ret['result']&&ret['data'].length>0? ret['data'][0]['photo']:null))
          this.userData['photo']=photo;
        })
        this.socialUserInfo$.subscribe(socialUserInfo => {
          this.socialUserInfo = socialUserInfo;
        })
      }
      else {
        this.userData = null
        this.socialUserInfo = null
      }
    })
    this.items = [
      {
        label: '會員管理',
        icon: 'pi pi-fw pi-user-edit',
        command: () => {
          this.navCtrl.navigateRoot(`tabs/profile/memberInfo`)
        }
      },
      {
        label: '登出',
        icon: 'pi pi-fw pi-sign-out',
        command: () => {
          this.userService.logout();
        }
      },
    ];
    this.healthItems = [
      // {
      //   label: '醫師專欄',
      //   command: () => {
      //     this.navCtrl.navigateRoot(`tabs/column-dashboard`)
      //   }
      // },
      {
        label: '專欄',
        command: () => {
          this.navCtrl.navigateRoot(`tabs/column-dashboard`)
        }
      },
    ];
    this.reqQuestionItems = [
      {
        label: '科別諮詢',
        command: () => {
          this.navCtrl.navigateForward('tabs/ask-question-flow/question-req',{ queryParams: { type: 'dept'} })
        }
      },
      {
        label: '症狀諮詢',
        command: () => {
          this.navCtrl.navigateForward('tabs/ask-question-flow/question-req',{ queryParams: { type: 'symptom'} })
        }
      },
      // {
      //   label: '直接諮詢',
      //   command: () => {
      //     this.navCtrl.navigateForward('tabs/ask-question-flow/condition-check',{ queryParams: { type: 'direct'} })
      //   }
      // },
      {
        label: '問題清單',
        command: () => {
          this.navCtrl.navigateForward('/tabs/question-dashboard')
        }
      },
    ];
  }
  goBackClick(event) {
    event.stopPropagation();
    this.goBack.emit(event)
  }
  goHome(event) {
    event.stopPropagation();
    this.navCtrl.navigateRoot('tabs/home')
  }
  goAbout(event) {
    event.stopPropagation();
    this.navCtrl.navigateRoot('tabs/about')
  }
  goToLogin(event) {
    event.stopPropagation();
    this.navCtrl.navigateRoot('tabs/login')
  }
  goDoctorDashboard(event) {
    event.stopPropagation();
    this.navCtrl.navigateRoot('tabs/doctor-dashboard')
  }
  onSearchClick(){

    if(this.keyinText&&this.keyinText.length>1)
    {

      if(this.mode=='redirect'){
        switch (this.redirectType) {
          case 'all':
            this.navCtrl.navigateForward('tabs/search-result',{ queryParams: { keyinText: this.keyinText} })
            break;
          case 'doctor':
            this.navCtrl.navigateForward('tabs/docotr-dashboard',{ queryParams: { keyinText: this.keyinText} })
            break;
          case 'column':
            this.navCtrl.navigateForward('tabs/column-dashboard',{ queryParams: { keyinText: this.keyinText} })
            break;
          case 'education':
            this.navCtrl.navigateForward('tabs/education-dashboard',{ queryParams: { keyinText: this.keyinText} })
            break;
          case 'question':
            this.navCtrl.navigateForward('tabs/question-dashboard',{ queryParams: { keyinText: this.keyinText} })
            break;
          default:
            this.navCtrl.navigateForward('tabs/search-result',{ queryParams: { keyinText: this.keyinText} })
            break;
        }

      }
      else{
        this.onKeyinTextChange.emit(this.keyinText)
      }
    }
    else{
      this.toastCtrl.create({
        message: '請至少輸入兩個字',
        duration: 3000
      }).then(toast=>{
        toast.present();
      });
    }
  }

  eventHandler(event){
    //鍵盤enter事件

    if(event==13){
      this.onSearchClick();
    }
  }

  onFocus(event){

  }

  onBlur(event){


    //this.expandContentDisplay=false;
   /* this.onSearchClick();*/
  }

  showFeqDialog(event,reqQuestion){
    console.log('event', event)
    event.stopPropagation();

    this.confirmationService.confirm({
      header: '智能客服',
      message: '使用智能客服可以即時回答',
      accept: () => {
        window.open('/chatroom', '_blank')
      }
    });

    // Swal.fire({
    //   title: '使用智能諮詢提示',
    //   text: "使用智能諮詢可以即時回答",
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   cancelButtonText: '不，我想要醫師回答',
    //   confirmButtonText: '是,使用智能諮詢'
    // }).then((result) => {
    //   if (result.value) {
    //     window.open('/chatroom', '_blank')
    //   }
    //   else{
    //       console.log('event', event)
    //       reqQuestion.toggle(event)
    //   }
    // })
  }


}
